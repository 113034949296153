import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import settings from './reducers/settingsReducer';
import { blog } from './reducers/blogReducer';
import { portfolio } from './reducers/portfolioReducer';

const rootReducer = combineReducers({
   settings,
   blog,
   portfolio
})

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk]
});

export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch