import { AnyAction } from 'redux';
import { BlogItemType } from '../../pages/types';
import { GET_POSTS, GET_POST } from '../actions/action-types';

type PostsState = {
    posts: BlogItemType[],
    post: BlogItemType | null
}
const initialState: PostsState = {
    posts: [],
    post: null
};
export function blog(
    state = initialState,
    action: AnyAction
): PostsState {
    switch (action.type) {
        case GET_POSTS:
            return { ...state, posts: action.posts }
        case GET_POST:
            return { ...state, post: action.post }
        default:
            return state;
    }
}