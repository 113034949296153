import { AnyAction } from 'redux';
import { PortfolioItemType } from '../../pages/types';
import { GET_PORTFOLIO_ITEM, GET_PORTFOLIO_ITEMS } from '../actions/action-types';

type PortfolioState = {
    portfolioItems: PortfolioItemType[],
    portfolioItem: PortfolioItemType | null
}
const initialState: PortfolioState = {
    portfolioItems: [],
    portfolioItem: null
};
export function portfolio(
    state = initialState,
    action: AnyAction
): PortfolioState {
    switch (action.type) {
        case GET_PORTFOLIO_ITEMS:
            return { ...state, portfolioItems: action.portfolioItems }
        case GET_PORTFOLIO_ITEM:
            return { ...state, portfolioItem: action.portfolioItem }
        default:
            return state;
    }
}