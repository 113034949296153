import React, { Suspense, lazy, useState, useEffect } from "react";
import './App.css';
import {
  Routes,
  Route
} from 'react-router-dom';
import ReactGA from "react-ga4";
import NotFound from "./pages/NotFound";

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <div style={{ color: "white" }}>LOADING</div>
    </div>
  );
}

const Main = lazy(() => import('./pages/Main'));
const About = lazy(() => import("./pages/About"));
const Portfolio = lazy(() => import("./pages/Portfolio"));

function App() {
  // Google Analytics
  ReactGA.initialize('G-WYSRDVMBYR');
  return (
    <div className="main-container">
      <Routes>
        <Route path="/" element={
          <Suspense fallback=
            {Loading()}>
            <Main />
          </Suspense>
        } />
        <Route path="/portfolio" element={
          <Suspense fallback=
            {Loading()}>
            <Portfolio />
          </Suspense>
        } />
        <Route path="/about" element={
          <Suspense fallback=
            {Loading()}>
            <About />
          </Suspense>
        } />
        <Route path='*' element={
          <Suspense fallback=
            {Loading()}>
            <Main />
          </Suspense>
        } />
      </Routes>
    </div>
  );
}

export default App;
