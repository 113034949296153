import { GET_TEAM } from '../actions/action-types';

const initialState = {
    ourTeam: []
}
export default (state = initialState, action) => {
    const { type, ourTeam } = action;
    switch (type) {
        case GET_TEAM:
            return { ...state, ourTeam }
        default:
            return state;
    }
}